<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('currency.currencies')"
                icon="mdi-currency-usd"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <!--Save popup-->
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card>
                        <v-toolbar color="primary darken-1" dark>
                            <v-card-title>
                                <span>{{
                                    (editedIndex === -1
                                        ? $t("add")
                                        : $t("edit")) +
                                        " " +
                                        $t("currency.currency")
                                }}</span>
                            </v-card-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            hide-details
                                            outlined
                                            dense
                                            persistent-hint
                                            v-model="editedItem.currencyName"
                                            :rules="rules"
                                            :label="$t('currency.currencyName')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            hide-details
                                            outlined
                                            dense
                                            :rules="rules"
                                            v-model="editedItem.currencySymbol"
                                            :label="
                                                $t('currency.currencySymbol')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            hide-details
                                            outlined
                                            dense
                                            type="number"
                                            min="0"
                                            v-model="editedItem.currencyValue"
                                            :label="
                                                $t('currency.currencyValue')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            hide-details
                                            outlined
                                            dense
                                            type="number"
                                            min="0"
                                            v-model="editedItem.floatingPoints"
                                            :label="
                                                $t('currency.floatingPoints')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-switch
                                            hide-details
                                            class="pt-0 mt-3"
                                            inset
                                            v-model="editedItem.isMain"
                                            :label="$t('currency.isMain')"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="save"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon>mdi-content-save-outline</v-icon>
                                {{ $t("save") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="red" @click="close">
                                {{ $t("cancel") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
            <!--Delete popup-->
            <confirm-dialog
                :openDialog="dialogDelete"
                :onClicked="deleteItemConfirm"
                :onClose="closeDelete"
                toolBarColor="red darken-2"
            ></confirm-dialog>
            <template>
                <v-row class="pa-10" justify="center">
                    <v-card
                        v-for="currency in currencies"
                        :key="currency.currencyGuid"
                        class="mb-10 mx-5"
                        max-width="250"
                        min-width="250"
                        :loading="loading"
                        rounded
                        shaped
                        outlined
                    >
                        <v-toolbar
                            :color="$vuetify.theme.dark ? '#2e5085' : '#dce9fc'"
                            :class="
                                $vuetify.theme.dark
                                    ? 'white--text'
                                    : 'black--text'
                            "
                            outlined
                            elevation="0"
                        >
                            <h3>
                                <v-icon
                                    size="16"
                                    :color="currency.isMain ? 'green' : 'red'"
                                    >mdi-circle</v-icon
                                >
                                {{
                                    currency.currencyName +
                                        " (" +
                                        currency.currencySymbol +
                                        ")"
                                }}
                            </h3>
                        </v-toolbar>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    <p>
                                        {{
                                            currency.isMain
                                                ? $t("currency.isMain")
                                                : $t("currency.notMain")
                                        }}
                                    </p>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <p>
                                        {{ $t("currency.currencyValue") }}:
                                        {{
                                            currency.currencyValue
                                                | currency(
                                                    "",
                                                    currency.floatingPoints
                                                )
                                        }}
                                    </p>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn
                                v-if="isInRole(20)"
                                color="primary darken-1"
                                dark
                                class="mx-2 my-1"
                                @click="editItem(currency)"
                            >
                                {{ $t("edit") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="isInRole(21)"
                                text
                                color="red"
                                class="mx-2 my-1"
                                @click="deleteItem(currency)"
                            >
                                {{ $t("delete") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>
            </template>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        ConfirmDialog,
        PageHeader
    },
    data() {
        return {
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            headers: [
                {
                    text: this.$t("currency.currencyName"),
                    value: "currencyName"
                },
                {
                    text: this.$t("currency.currencySymbol"),
                    value: "currencySymbol"
                },
                {
                    text: this.$t("currency.currencyValue"),
                    value: "currencyValue"
                },
                {
                    text: this.$t("currency.isMain"),
                    value: "isMain"
                },
                { text: "", value: "actions" }
            ],
            currencies: [],
            editedItem: {
                currencyGuid: "",
                currencyName: "",
                currencySymbol: "",
                currencyValue: 0.0,
                floatingPoints: 0,
                isMain: false
            },
            defaultItem: {
                currencyGuid: "",
                currencyName: "",
                currencySymbol: "",
                currencyValue: 0.0,
                floatingPoints: 0,
                isMain: false
            },

            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(19)) {
            this.loading = true;
            this.refreshTable();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.currencies.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.currencies.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.currencies[this.editedIndex];
            axios
                .delete("currencies/Delete?id=" + deleteItem.currencyGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.refreshTable();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshTable();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        refreshTable() {
            /* this.loading = this.$store.getters["currenciesStore/isLoading"];
            this.$store.dispatch("currenciesStore/getCurrencies", null);
            this.currencies = this.$store.state.currenciesStore.currencies;
            this.currencies = this.$store.getters[
                "currenciesStore/getCurrencies"
            ]; */

            axios
                .get("Currencies/Get")
                .then(response => {
                    this.currencies = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex == -1) {
                    this.editedItem.currencyGuid =
                        "00000000-0000-0000-0000-000000000000";
                }
                axios
                    .post("Currencies/Save", this.editedItem)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.refreshTable();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.close();
                        this.refreshTable();
                    });
            }
        }
    }
};
</script>
